<template>
  <div class="rec_detail">
    <div class="video_content" :class="toggle ? 'middle' : 'big'">
      <div class="video_content_header flex_space">
        <div class="video_content_header_l">
          <span @click="$router.go(-1)">
            <i class="el-icon el-icon-d-arrow-left"></i>
            返回
          </span>
          <span>{{ detailInfo.gradeName }} · {{ detailInfo.subjectName }}</span>
          <span>{{ detailInfo.title }}</span>
        </div>
        <div class="video_content_header_r">
          <span>
            <label>{{ detailInfo.countCollect }} </label>
            收藏
          </span>
          <span>分享</span>
          <span @click="expand">
            <i
              class="el-icon"
              :class="toggle ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'"
            >
            </i>
          </span>
        </div>
      </div>
      <div class="video_content_body">
        <video :src="detailInfo.videoAdd" controls autoplay muted></video>
      </div>
      <div class="video_content_footer flex_space">
        <div class="video_content_footer_l">
          <img
            v-if="detailInfo.avatar"
            :src="detailInfo.avatar"
            alt=""
            class="avatar"
          />
          <d-avatar v-else :size="40"></d-avatar>
          <div class="teacher_infos">
            <div>{{ detailInfo.teacher }}</div>
            <div>{{ detailInfo.countFollow }}人已关注</div>
          </div>
          <div class="line"></div>
          <div class="attention">关注</div>
        </div>
        <div
          @click="downloadDialogVisible = true"
          class="video_content_footer_r"
        >
          <i class="el-icon el-icon-download"></i>
          资料下载
        </div>
      </div>
    </div>
    <div
      class="class_infos"
      :class="toggle ? 'show_class_infos' : 'hide_class_infos'"
    >
      <div class="class_infos_header">
        <div
          class="tabs_item"
          :class="tabsActive === 0 ? 'active' : ''"
          @click="tabsActive = 0"
        >
          课程信息
        </div>
        <div
          class="tabs_item border"
          :class="tabsActive === 1 ? 'active' : ''"
          @click="tabsActive = 1"
        >
          讨论
        </div>
        <div
          class="tabs_item"
          :class="tabsActive === 2 ? 'active' : ''"
          @click="tabsActive = 2"
        >
          相关推荐
        </div>
      </div>
      <div class="class_infos_body">
        <div class="infos" v-if="tabsActive === 0">
          <div class="knowledge">
            <div>知识点：</div>
            <div
              v-for="item in detailInfo.knowledgeJson"
              :key="item.chapterId"
              class="knowledge_item"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="course_desc">
            {{ detailInfo.introduce }}
          </div>
          <div class="knowledge_indexs">
            <div class="name">知识点索引</div>
            <div class="knowledge_indexs_list">
              <div
                v-for="item in detailInfo.knowledgeJson"
                :key="item.chapterId"
                :class="
                  detailInfo.activeKnow === item.chapterId ? 'active' : ''
                "
                class="knowledge_indexs_item"
              >
                <div class="point"></div>
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="discuss" v-else-if="tabsActive === 1">
          <div class="comments_list">
            <div
              v-for="(item, index) in detailInfo.discussList"
              :key="index"
              class="comments_item"
            >
              <div class="user_infos">
                <div class="user_infos_l">
                  <img
                    v-if="item.avatar"
                    :src="item.avatar"
                    alt=""
                    class="avatar"
                  />
                  <d-avatar v-else :size="30"></d-avatar>
                  <div class="user_name">{{ item.name }}</div>
                </div>
                <div class="user_infos_r">
                  <div class="time">{{ item.time }}</div>
                  <div class="del">
                    <i class="el-icon el-icon-delete"></i>
                  </div>
                </div>
              </div>
              <div class="comment_content">
                {{ item.content }}
              </div>
            </div>
          </div>
          <div class="send_comment">
            <el-input
              type="textarea"
              placeholder="说点什么跟大家一起讨论讨论吧~"
              v-model="comment"
              maxlength="200"
              show-word-limit
              class="send_comment_input"
            >
            </el-input>
            <el-button type="primary" size="mini">发起讨论</el-button>
          </div>
        </div>
        <div class="recommend" v-else-if="tabsActive === 2">
          <div class="recommend_course_list">
            <div
              class="recommend_course_item"
              v-for="(item, index) in detailInfo.recomList"
              :key="index"
            >
              <div class="img">
                <img :src="item.cover" alt="" />
              </div>
              <div class="title">
                <div class="tags">
                  {{ item.gradeName }}·{{ item.subjectName }}
                </div>
                <div class="course_name">
                  {{ item.title }}
                </div>
              </div>
              <div class="recommend_teacher_infos">
                <img
                  v-if="item.avatar"
                  :src="item.avatar"
                  alt=""
                  class="avatar"
                />
                <d-avatar v-else :size="30"></d-avatar>
                <div class="recommend_teacher_text">
                  <div>{{ item.teacher }}</div>
                  <div>
                    <span>{{ item.school }}</span>
                    <span>{{ item.countStu }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 资料下载弹窗 -->
    <el-dialog
      v-model="downloadDialogVisible"
      append-to-body
      title="资料下载"
      width="1000px"
      custom-class="download_dialog"
      :close-on-click-modal="false"
    >
      <div class="source_list">
        <div
          class="source_item"
          v-for="(item, index) in detailInfo.sourceList"
          :key="index"
        >
          <div class="source_infos flex">
            <div :class="item.ext" class="source_img">
              <svg-icon :icon-class="item.ext"></svg-icon>
            </div>
            <div class="source_txt">
              <div class="source_name">{{ item.name }}</div>
              <div class="source_size_time">
                <span>{{ item.size }}</span>
                <span>{{ item.time }}</span>
              </div>
            </div>
          </div>
          <div class="btns flex">
            <div>预览</div>
            <div>下载</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonApi from "@/api/common";
import { getAssetsFile } from "@/scripts/utils";
export default {
  data() {
    return {
      tabsActive: 0,
      comment: "",
      downloadDialogVisible: false,
      sourceList: [
        {
          name: "学习任务的名称如马克思主义理论等",
          size: "9.27M",
          time: "2021-01-12 09:12",
        },
        {
          name: "学习任务的名称如马克思主义理论等",
          size: "9.27M",
          time: "2021-01-12 09:12",
        },
        {
          name: "学习任务的名称如马克思主义理论等",
          size: "9.27M",
          time: "2021-01-12 09:12",
        },
      ],
      toggle: true,
      detailInfo: {},
      activeKnow: "",
    };
  },
  mounted() {
    this.loadAssets();
  },
  methods: {
    expand() {
      this.toggle = !this.toggle;
    },
    // 添加假数据
    async loadAssets() {
      const res = await commonApi.GetDocData("rec_detail");
      if (res && res.code === 200) {
        const avaSuffix = res.data.detail.avatar.split(".")[1];
        const vdSuffix = res.data.detail.videoAdd.split(".")[1];
        this.detailInfo = {
          ...res.data.detail,
          avatar: getAssetsFile(res.data.detail.avatar, avaSuffix),
          videoAdd: getAssetsFile(res.data.detail.videoAdd, vdSuffix),
          discussList: res.data.detail.discussList.map((item) => {
            const suffix = item.avatar.split(".")[1];
            return {
              ...item,
              avatar: getAssetsFile(item.avatar, suffix),
            };
          }),
          recomList: res.data.detail.recomList.map((item) => {
            const imgSuffix = item.cover.split(".")[1];
            const avaSuffix = item.avatar.split(".")[1];
            return {
              ...item,
              cover: getAssetsFile(item.cover, imgSuffix),
              avatar: getAssetsFile(item.avatar, avaSuffix),
            };
          }),
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/normalize";
.rec_detail {
  width: 100%;
  height: 100%;
  display: flex;
  .video_content {
    .video_content_header {
      width: 100%;
      height: 60px;
      background-color: #171717;
      color: #fff;
      .video_content_header_l {
        span {
          display: inline-block;
        }
        span:nth-child(1) {
          width: 80px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border-radius: 0 4px 4px 0;
          background-color: #212121;
          color: #999999;
          margin-right: 10px;
          font-size: 14px;
          cursor: pointer;
          i {
            font-size: 12px;
            margin-right: 3px;
          }
        }
        span:nth-child(2) {
          width: 80px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          color: #f27200;
          font-size: 12px;
          border: 1px solid #f27200;
          border-radius: 4px;
          margin-right: 10px;
        }
        span:nth-child(3) {
          color: #fff;
        }
      }
      .video_content_header_r {
        span {
          display: inline-block;
        }
        span:nth-child(1) {
          font-size: 12px;
          color: #fff;
          margin-right: 20px;
          label {
            color: #f27200;
          }
          i {
            color: #f27200;
          }
        }
        span:nth-child(2) {
          width: 48px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          color: #ffffff;
          font-size: 12px;
          background-color: #f27200;
          border-radius: 1px;
          margin-right: 20px;
          cursor: pointer;
        }
        span:nth-child(3) {
          width: 20px;
          height: 20px;
          border-radius: 2px 0px 0px 2px;
          background: #1d222e;
          text-align: center;
          line-height: 20px;
          color: #666666;
          cursor: pointer;
        }
      }
    }
    .video_content_body {
      width: 100%;
      height: calc(100% - 140px);
      background-color: black;
      video {
        width: 100%;
        height: 100%;
      }
    }
    .video_content_footer {
      width: calc(100% - 40px);
      height: 40px;
      padding: 20px;
      background-color: #101010;
      .video_content_footer_l {
        display: flex;
        align-items: center;
        .teacher_infos {
          margin-left: 10px;
          div:nth-child(1) {
            color: #ffffff;
            font-size: 14px;
            margin-bottom: 10px;
          }
          div:nth-child(2) {
            color: #999999;
            font-size: 12px;
          }
        }
        .line {
          width: 1px;
          height: 40px;
          background-color: #999;
          margin-right: 20px;
          margin-left: 20px;
        }
        .attention {
          width: 48px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          color: #ffffff;
          font-size: 12px;
          border-radius: 1px;
          background-color: #f27200;
          cursor: pointer;
        }
      }
      .video_content_footer_r {
        color: #ffffff;
        cursor: pointer;
        i {
          font-size: 16px;
          margin-right: 5px;
        }
      }
    }
  }
  .middle {
    width: calc(100% - 320px);
    height: 100%;
  }
  .big {
    width: 100%;
    height: 100%;
  }
  .class_infos {
    width: 320px;
    height: 100%;
    background-color: #151923;
    .class_infos_header {
      display: flex;
      align-items: center;
      width: 100%;
      height: 59px;
      border-bottom: 1px solid #2e3442;
      .tabs_item {
        width: calc((100% - 2px) / 3);
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #999999;
        font-size: 14px;
        cursor: pointer;
      }
      .border {
        border-left: 1px solid #2e3442;
        border-right: 1px solid #2e3442;
      }
      .active {
        color: #ff8a00;
      }
    }
    .class_infos_body {
      width: 100%;
      height: calc(100% - 60px);
      .infos {
        width: calc(100% - 40px);
        height: calc(100% - 100px);
        padding: 20px;
        .knowledge {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          color: #ffffff;
          font-size: 14px;
          .knowledge_item {
            width: 60px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            color: #999999;
            font-size: 12px;
            border: 1px solid #2e3442;
            margin-right: 10px;
          }
        }
        .course_desc {
          width: calc(100% - 20px);
          height: 40px;
          padding: 10px;
          background-color: #2e3442;
          color: #999999;
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 40px;
        }
        .knowledge_indexs {
          .name {
            margin-bottom: 20px;
            color: #ffffff;
            font-size: 14px;
          }
          .knowledge_indexs_list {
            border-left: 2px solid #2e3442;
            .knowledge_indexs_item {
              position: relative;
              color: #999999;
              font-size: 12px;
              padding-left: 15px;
              margin-bottom: 40px;
              cursor: pointer;
              .point {
                position: absolute;
                top: 4px;
                left: -5px;
                width: 8px;
                height: 8px;
                border-radius: 8px;
                background-color: #2e3442;
              }
              .point_active {
                background-color: #ff8a00;
              }
            }
            .active {
              color: #ff8a00;
            }
          }
        }
      }
      .discuss {
        width: 100%;
        height: 100%;
        .comments_list {
          width: calc(100% - 40px);
          height: calc(100% - 240px);
          padding: 20px;
          overflow-y: auto;
          .comments_item {
            border-bottom: 1px solid #666666;
            margin-bottom: 20px;
            .user_infos {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
              .user_infos_l {
                display: flex;
                align-items: center;
                .user_name {
                  color: #ff8a00;
                  font-size: 14px;
                  margin-left: 10px;
                }
              }
              .user_infos_r {
                display: flex;
                align-items: center;
                color: #666666;
                .time {
                  font-size: 12px;
                  margin-right: 10px;
                }
                .del {
                  font-size: 14px;
                  cursor: pointer;
                }
              }
            }
            .comment_content {
              color: #666666;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 20px;
            }
          }
        }
        .send_comment {
          position: relative;
          width: calc(100% - 40px);
          height: 160px;
          padding: 20px;
          background-color: #1d212a;
          .el-button {
            position: absolute;
            right: 20px;
            bottom: 20px;
            padding: 0 10px;
          }
        }
      }
      .recommend {
        width: calc(100% - 40px);
        height: calc(100% - 100px);
        padding: 20px;
        .recommend_course_list {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          .recommend_course_item {
            border-bottom: 1px solid #2e3442;
            margin-bottom: 20px;
            .img {
              width: 100%;
              height: 160px;
              margin-bottom: 20px;
              border-radius: 4px 4px 0px 0px;
              background-color: #999999;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .title {
              display: flex;
              align-items: center;
              margin-bottom: 15px;
              .tags {
                width: 80px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                font-size: 12px;
                background-color: #b655c2;
                border-radius: 2px;
                margin-right: 10px;
              }
              .course_name {
                width: calc(100% - 90px);
                color: #ffffff;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .recommend_teacher_infos {
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              .recommend_teacher_text {
                width: calc(100% - 40px);
                margin-left: 10px;
                div:nth-child(1) {
                  color: #ffffff;
                  font-size: 14px;
                  margin-bottom: 3px;
                }
                div:nth-child(2) {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  span {
                    color: #999999;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .show_class_infos {
    display: block;
  }
  .hide_class_infos {
    display: none;
  }
  .flex_space {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}
</style>

<style lang="scss">
.send_comment_input {
  width: 100%;
  height: 100%;
  .el-textarea__inner {
    height: 100%;
    resize: none;
    color: #999999;
    font-size: 12px;
    background-color: rgba($color: #000000, $alpha: 0);
    border: 0px;
  }
  .el-input__count {
    left: 20px;
    bottom: 5px;
    color: #999999;
    font-size: 12px;
    background-color: rgba($color: #000000, $alpha: 0);
  }
}
.download_dialog {
  height: 520px;
  .el-dialog__header {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #dddddd;
    .el-dialog__title {
      color: #333;
      font-size: 16px;
    }
    .el-dialog__headerbtn {
      top: 24px;
    }
  }
  .el-dialog__body {
    width: calc(100% - 40px);
    height: calc(100% - 65px - 40px);
    padding: 20px;
    .source_list {
      width: calc(100% - 20px);
      height: 100%;
      padding-right: 20px;
      overflow-y: auto;
      .source_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 20px;
        padding-bottom: 20px;
        .source_infos {
          .ppt {
            background-color: rgba($color: #ff674e, $alpha: 0.1);
          }
          .word {
            background-color: rgba($color: #5686df, $alpha: 0.1);
          }
          .pdf {
            background-color: rgba($color: #ff7272, $alpha: 0.1);
          }
          .video {
            background-color: rgba($color: #fb673d, $alpha: 0.1);
          }
          .zip {
            background-color: rgba($color: #379cff, $alpha: 0.1);
          }
          .source_img {
            width: 60px;
            height: 60px;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            svg {
              font-size: 30px;
            }
          }
          .source_txt {
            .source_name {
              color: #333333;
              font-size: 20px;
              margin-bottom: 25px;
            }
            .source_size_time {
              color: #999999;
              font-size: 14px;
              span {
                margin-right: 50px;
              }
            }
          }
        }
        .btns {
          div {
            width: 60px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            color: #ffffff;
            font-size: 14px;
            border-radius: 4px;
            cursor: pointer;
          }
          div:nth-child(1) {
            background-color: #3984fc;
            margin-right: 20px;
          }
          div:nth-child(2) {
            background-color: #f27200;
          }
        }
      }
    }
  }
}
</style>
